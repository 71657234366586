<template>
  <VContainer>
    <div>{{ this.error }}</div>
    <RouterLink :to="{ name: 'Home' }"> Go back </RouterLink>
  </VContainer>
</template>
<script>
export default {
  name: "DisplayError",
  computed: {
    error() {
      return this.$route.params.error;
    }
  }
};
</script>
